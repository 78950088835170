import React, {useEffect} from "react";
import styled from "styled-components";
import bg_icon from '../asset/down_bg.png'
import button1 from '../asset/down_android.png'
import button2 from '../asset/down_ios.png'
import app_icon from '../asset/down_app.png'
import there_icon from '../asset/down_there.png'

function DownPage() {

    useEffect(() => {

        let el = document.getElementById("mask")
        let ua = window.navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) === 'micromessenger') {
            el.style.display = "block"
        } else {
            el.style.display = "none"
        }
    }, [])

    return <Content>
        <Mask id='mask'>
            <ColWrapper2>
                <ThereImage src={there_icon}/>
                <Title3>1.请先点击右上角按钮</Title3>
                <Title3>2.然后在浏览器中打开</Title3>
            </ColWrapper2>
        </Mask>
        <ColWrapper>
            <Title1>欢迎来到竞时代</Title1>
            <Title2>让上网的时刻变得更有趣</Title2>
            <RowWrapper>
                <Button1 onClick={() => {
                    window.location = "https://pc-app.skiiiii.com/ETPN_Android.apk"
                }}/>
                <Button2 onClick={() => {
                    window.location = "https://apps.apple.com/cn/app/%E7%AB%9E%E6%97%B6%E4%BB%A3/id1605530738"
                }}/>
            </RowWrapper>
            <AppImage src={app_icon}/>
        </ColWrapper>
        <Title4 onClick={() => {
            window.open("https://icp.chinaz.com/home/info?host=skiiiii.com")
        }}>
            琼ICP备2020003771号-1
        </Title4>

    </Content>
}

export default DownPage;

const Content = styled.div`
   background: url(${bg_icon}) no-repeat;
   background-size: cover;
   width: 100vw;
   min-height: 100vh;
`

const RowWrapper = styled.div`
    display: flex;
    flex-direction: row;  
`

const ColWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
`

const Title1 = styled.div`
    font-size: 32px;
    font-weight: 600;
    color: #FFFFFF; 
    margin: 50px 0 5px 0;
`

const Title2 = styled.div`
    font-size: 16px;
    color: #FFFFFF;
    margin-bottom: 40px;
`

const Button1 = styled.div`
    width: 120px;
    height: 36px;
    background: url(${button1}) no-repeat;
    background-size: cover;
    margin-right: 5px;
`

const Button2 = styled.div`
    width: 120px;
    height: 36px;
    background: url(${button2}) no-repeat;
    background-size: cover;
    margin-left: 5px;
`

const AppImage = styled.img`
    width: 273px;
    height: 513px;
    margin-top: 20px;
    margin-bottom: 50px;
`

const ColWrapper2 = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`

const Mask = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.6);
`
const ThereImage = styled.img`
    width: 100px;
    height: 100px;
`

const Title3 = styled.div`
    font-size: 22px;
    font-weight: 600;
    color: #FFFFFF; 
    margin: 10px 0 5px 0;
    width: 100%;
    text-align: center;
`

const Title4 = styled.div` 
    text-align: center;
    font-size:16px;
    cursor: pointer;
    padding-bottom: 20px;
    color: #FFFFFF;
    
    &:hover {
        color: blue;
    }
    
`









