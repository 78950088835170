import React from 'react';
import { createRoot } from 'react-dom/client';
import { HashRouter,Route} from 'react-router-dom'
import DownPage from "./page/DownPage";


const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<React.StrictMode>
    <HashRouter>
        <Route exact strict path="/" component={DownPage}/>
        <Route exact strict path="/download" component={DownPage}/>
    </HashRouter>
</React.StrictMode>);
